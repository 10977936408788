/* eslint-disable prefer-destructuring */
/* eslint-disable no-prototype-builtins */
import { CognitoJwtVerifier } from 'aws-jwt-verify';
import axios from 'axios';
import { LifeCycles, registerApplication, start } from 'single-spa';

import { Cookie } from './auth/cookie';
import {
  getStoreSelected,
  setGAEvents,
  setStoreSelected,
  setTraceIdInterceptors,
} from './utils/utils';

const idToken = Cookie.get('idToken');
let redirect = true;

const userPoolId = process.env.REACT_APP_USERPOOL_ID;
const clientId = process.env.REACT_APP_CLIENT_ID;
const authUrl = process.env.REACT_APP_IDENTITY_URL;
const apiBaseURL = process.env.REACT_APP_API_INTERNAL_URL;

const dev = process.env.NODE_ENV === 'development';

export const verifier = CognitoJwtVerifier.create({
  userPoolId: `${userPoolId}`,
  tokenUse: 'id',
  clientId: `${clientId}`,
});

const runApp = function (profileData) {
  registerApplication({
    name: '@Estoca/core',
    app: () => System.import<LifeCycles>('@Estoca/core'),
    activeWhen: [location => location.pathname.startsWith('/')],
    customProps: { profileData },
  });

  registerApplication({
    name: '@Estoca/dashboard',
    app: () => System.import<LifeCycles>('@Estoca/dashboard'),
    activeWhen: [
      '/home',
      '/dashboard',
      location => location.pathname.endsWith('/'),
    ],
    customProps: { profileData },
  });

  registerApplication({
    name: '@Estoca/analytics',
    app: () => System.import<LifeCycles>('@Estoca/analytics'),
    activeWhen: ['/analytics'],
    customProps: { profileData },
  });

  registerApplication({
    name: '@Estoca/inbound',
    app: () => System.import<LifeCycles>('@Estoca/inbound'),
    activeWhen: ['/inbound'],
    customProps: { profileData },
  });

  registerApplication({
    name: '@Estoca/outbound',
    app: () => System.import<LifeCycles>('@Estoca/outbound'),
    activeWhen: ['/outbound'],
    customProps: { profileData },
  });

  registerApplication({
    name: '@Estoca/inventory',
    app: () => System.import<LifeCycles>('@Estoca/inventory'),
    activeWhen: ['/inventory'],
    customProps: { profileData },
  });

  start({
    urlRerouteOnly: true,
  });
};

const initializeData = async () => {
  setTraceIdInterceptors(idToken);

  try {
    const { data } = await axios.get(
      `${apiBaseURL}/profile/me?page=1&per_page=100`
    );

    const isAdmin = data.groups.findIndex(group => group.name === 'admin');

    if (isAdmin >= 0) {
      const response = await axios.get(
        `${apiBaseURL}/profile/store?page=1&per_page=100`
      );
      data.stores = response.data.data;
    }

    const storeSelected = await getStoreSelected();

    if (!storeSelected || !storeSelected.hasOwnProperty('id')) {
      data.storeSelected = data.stores[0];
      setStoreSelected(data.storeSelected);
    } else {
      data.storeSelected = storeSelected;
    }
    setGAEvents(data.storeSelected, data.id);

    runApp(data);
  } catch (err) {
    if (redirect) {
      window.location.href = `${authUrl}?returnUrl=${window.location.href}`;
    }
  }
};

if (dev) {
  redirect = false;
  initializeData();
} else {
  verifier
    .verify(idToken)
    .then(response => {
      const hasResponse = !!Object.keys(response).length;

      if (!hasResponse && redirect) {
        window.location.href = `${authUrl}?returnUrl=${window.location.href}`;
      }

      initializeData();
    })
    .catch(() => {
      if (redirect) {
        window.location.href = `${authUrl}?returnUrl=${window.location.href}`;
      }
    });
}
