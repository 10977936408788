/* eslint-disable no-prototype-builtins */
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { Cookie } from '../auth/cookie';

export const getStoreSelected = async () => {
  const apiBaseURL = process.env.REACT_APP_API_INTERNAL_URL;
  const internalStoreSelected = JSON.parse(Cookie.get('storeSelected'));
  const externalStoreSelected = Cookie.get('storeSelectedId');

  if (
    internalStoreSelected &&
    internalStoreSelected.hasOwnProperty('id') &&
    internalStoreSelected.id === externalStoreSelected
  ) {
    return internalStoreSelected;
  }
  if (externalStoreSelected) {
    const response = await axios.get(
      `${apiBaseURL}/profile/store/${externalStoreSelected}`
    );
    return response.data.data[0];
  }
  if (
    internalStoreSelected &&
    internalStoreSelected.hasOwnProperty('id') &&
    internalStoreSelected.id &&
    !externalStoreSelected
  ) {
    return internalStoreSelected;
  }
  return Cookie.get('storeSelected')
    ? JSON.parse(Cookie.get('storeSelected'))
    : null;
};

export const setStoreSelected = (store: Record<string, unknown>) => {
  Cookie.set('storeSelected', JSON.stringify(store), 7);
};

export const getTraceId = () => {
  return Cookie.get('traceId') ? Cookie.get('traceId') : null;
};

export const setTraceId = (traceId: string) => {
  Cookie.set('traceId', traceId, 1 / 24);
};

export const setTraceIdInterceptors = (token: string) => {
  // Add default authorization headers in axios
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;

  // Add a request interceptor to always include Trace-Id
  axios.interceptors.request.use(
    config => {
      const actualTraceId = getTraceId();
      if (actualTraceId && !config.headers.hasOwnProperty('X-Trace-Id')) {
        config.headers['X-Trace-Id'] = actualTraceId;
      } else if (!actualTraceId) {
        const newTraceId = uuidv4();
        setTraceId(newTraceId);
        config.headers['X-Trace-Id'] = newTraceId;
      }

      return config;
    },
    error => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    response => {
      const responseTraceId = response.headers?.['X-Trace-Id'];
      const actualTraceId = getTraceId();

      if (
        responseTraceId &&
        actualTraceId &&
        responseTraceId !== actualTraceId
      ) {
        setTraceId(responseTraceId);
      } else {
        setTraceId(actualTraceId);
      }
      return response;
    },
    error => {
      return Promise.reject(error);
    }
  );
};

export const identifyUserGuiding = data => {
  if (window && window.userGuiding.identify && window.userGuiding.identify) {
    window.userGuiding.identify(data.id, {
      email: data.email,
      name: data.first_name,
      store: data.storeSelected.name,
    });
  }
};

export const setGAEvents = (store, person) => {
  if (window && window.dataLayer && window.dataLayer.length > 0) {
    window.dataLayer.push({
      company_type: 'ecommerce',
      event: 'gtm-platform',
      store_id: store.id,
      store_type: store.is_saas ? 'saas' : 'full',
      user_id: person,
    });
  }
};
